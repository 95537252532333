
import { defineComponent, reactive } from "vue";
import { useStore } from "vuex";
import { useHead } from "@vueuse/head";
import { useRoute, useRouter } from "vue-router";

import CSpinner from "@/components/spinner.vue";
import { ProcedureCartForm } from "@/store/cart/types";

const VLaboratoryAdd = defineComponent({
  name: "VLaboratoryAdd",
  components: { CSpinner },
  setup() {
    useHead({ title: "Agendar exame laboratorial | obmed" });

    const store = useStore();
    const route = useRoute();
    const router = useRouter();

    const loading = reactive({ submit: false });

    const form = reactive<ProcedureCartForm>({
      cd_procedimento:
        route.query.le
          ?.toString()
          ?.split(",")
          ?.map((_id) => Number(_id)) || [],
      cd_estabelecimento: Number(route.query.et?.toString()) || null,
      cd_pessoa_fisica: Number(route.query.pf?.toString()) || null,
    });

    function showErrorMessage() {
      store.commit("addToast", {
        summary: "OPS... Tivemos problemas ao agendar, tente novamente mais tarde!",
        severity: "error",
      });
      router.back();
    }

    function validate() {
      return form.cd_estabelecimento && form.cd_pessoa_fisica && form.cd_procedimento.length && Number(route.query.hr);
    }

    async function addProcedureToCart() {
      loading.submit = true;
      const response = await store.dispatch("addProcedureToCart", {
        _id: Number(route.query.hr),
        form,
      });
      loading.submit = false;

      if (!response) return router.back();
      if (response.status !== 200) return showErrorMessage();

      store.commit("setToast", [{ summary: "Agendamento adicionado ao carrinho!", severity: "success" }]);
      router.replace({ name: "cart" });
    }

    if (validate()) addProcedureToCart();
    else {
      showErrorMessage();
      router.replace({ name: "laboratory", query: route.query });
    }

    return { loading };
  },
});

export default VLaboratoryAdd;
